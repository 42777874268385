import "./success.css";
import React from "react";

function Success() {
  return (
    <body>
      <div id="login">
        <div class="container">
          <div class="text-center">
            <img
              src="https://healthsy.vps.webdock.cloud/images/16903053367720.7236385375603198-16746468814330.7478995832141409-healthsy-logo.png"
              title="HealthSy Logo"
              width="300"
            />
          </div>
          <div
            id="login-row"
            class="row justify-content-center align-items-center"
          >
            <div id="login-column" class="col-md-6">
              <div id="login-box" class="col-md-12">
                <form class="form" action="" method="post">
                  <h3 class="text-center text-info">
                    Password reset Successful!
                  </h3>
                  <p class="text-center">
                    Awesome, you've successfully updated your password. You may
                    login using your new password into the application.
                  </p>
                  <hr />
                  <h5>Facing issue?</h5>
                  <p>
                    Report the issue to support team{" "}
                    <a href="mail:support@healthsy.app">support@healthsy.in</a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Success;
