import "./App.css";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import ResetPassword from "./component/Reserpassword";
import Success from "./component/sucess";
import Invalid from "./component/Invalid";

function App() {
  return (
    <Router>
       <Switch>
       <div className="App">
      <Route path="/resetpassword/:userId/:date" component={ResetPassword} />
      <Route path="/Success" component={Success} />
      <Route path="/Invalid" component={Invalid} />
      </div >
      </Switch>
    </Router>
  );
}

export default App;
