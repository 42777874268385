import "./Resetpassword.css";
import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  let history = useHistory();
  const { userId } = useParams();
  const { date } = useParams();

  async function handleChange(e) {
    let sub = new Date().getTime();
    let findtotal = parseInt(date) - sub;
    let dif = Math.round(findtotal / 1000 / 60);

    if (dif < 60) {
      e.preventDefault();
      let data = {
        newpassword: password,
        vendorId: userId,
      };
      axios
        .post(
          "https://adminservice.healthsy.in:8000/vendor/resetPassword",
          data
        )
        .then((res) => {
          if (res.data.success === 1) {
            setPassword("");
            setConfirmpassword("");
            history.push("/Success");
          } else if (res.data.success === 0) {
            history.push("/Invalid");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      history.push("/Invalid");
    }
  }

  return (
    <body>
      <div id="login">
        <div class="container">
          <div class="text-center">
            <img
              src="https://healthsy.vps.webdock.cloud/images/16903053367720.7236385375603198-16746468814330.7478995832141409-healthsy-logo.png"
              title="HealthSy Logo"
              width="300"
            />
          </div>
          <div
            id="login-row"
            class="row justify-content-center align-items-center"
          >
            <div id="login-column" class="col-md-6">
              <div id="login-box" class="col-md-12">
                <form class="form" action="" method="post">
                  <h3 class="text-center text-info">Reset Password!</h3>
                  <div class="form-group">
                    <label for="username" class="text-info">
                      New Password:
                    </label>
                    <br />
                    <input
                      type="password"
                      name="username"
                      id="username"
                      class="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div class="form-group" />
                  <label for="password" class="text-info">
                    Re-enter Password:
                  </label>
                  <br />
                  <input
                    type="password"
                    name="password"
                    id="password"
                    class="form-control"
                    value={confirmpassword}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                  />
                  <div />
                  <div class="text-right">
                    <a
                      href="#"
                      class="btn btn-block btn-success"
                      onClick={handleChange}
                    >
                      Verify
                    </a>
                  </div>
                  <hr />
                  <h5>Facing issue?</h5>
                  <p>
                    Report the issue to support team{" "}
                    <a href="mail:support@healthsy.app">support@healthsy.in</a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default ResetPassword;
